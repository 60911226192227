
body {
  margin: 0;
  font-family: 'Pokemon', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

code {
  font-family: 'Pokemon', source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

