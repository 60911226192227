#workout-warrior {
    position: absolute;
    top: 20vh;
    height: 80vh;
    width: 100%;
    background-image: url('../img/ww-backer.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    /* stretch the image to fill the container */
}

.win-coins {
  position: absolute;
  right: 0;
  height: 100vh;
  color: white; /* White text color */
  z-index: 99999; /* Ensure the log view is on top of other elements */
}

@keyframes slide-in-sign {
  from {
    transform: translateY(100%);
  }
  to {
    transform:  translateY(0);
  }
}

@keyframes slide-in {
  from {
    transform: translateX(-50%) translateY(100%);
  }
  to {
    transform: translateX(-50%) translateY(0);
  }
}


@keyframes slideOutDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100vh); /* 100vh will push it entirely outside of the viewport */
  }
}

.slideOutDown {
  animation: slideOutDown 1s ease-out forwards;
}


.sergeant {
  /* animation: slide-in 1s ease-out forwards; */
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.sergeant img {
  width: 70vw;
  height: auto;
}

.game-view {
    position: relative;
    font-family: Pokemon;

    /* make the GameView a reference for absolute positioning */
}

.flipboard {
  position: absolute;
  bottom: 0;
  right: 0;
  animation: slide-in-sign 0.5s 3s ease-out forwards, wobble 2s 3.5s ease-in-out infinite;
  transform-origin: bottom center;
  transform: translateY(100%);
}

@keyframes specialShrink {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.shrinkImage {
  position: absolute;
  bottom: 0;
  right: 0;
  animation: specialShrink 0.5s ease-out forwards;
  /* transform-origin: bottom center; */
}

.flipboard img {
    max-width: 38vw;
    max-height: 100vh;
    height: auto;
}

.flipboard img:nth-child(2) {
    position: absolute;
    top: 45%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    max-width: 50%; /* adjust this to resize the image */
    max-height: 40%; /* adjust this to resize the image */
  }


  @keyframes pop-in {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

  @keyframes wobble {
    0% { transform: rotate(0deg); }
    25% { transform: rotate(-5deg); }
    50% { transform: rotate(0deg); }
    75% { transform: rotate(5deg); }
    100% { transform: rotate(0deg); }
  }
  
  .speech-bubble {
    position: absolute;
    bottom: 20%;
    left: 2vh;
    animation: pop-in 0.25s 1s ease-out forwards;
    transform-origin: bottom center;
    transform: scale(0);
    width: 30vw;
    height: 70vh;
    background: url('../img/speech-bubble-v2.png') no-repeat center center;
    background-size: contain;
}

.shrinkSpeech {
  animation: specialShrink 1s ease-out forwards;
  transform-origin: center;
}
  
.speech-text {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  z-index: 2;
  display: flex;
  width: 95%;
  align-items: center;
  justify-content: center;
}
  .speech-text p {
    font-size: 4.75vh;
    text-align: center;
    left: 1vh;
    margin-bottom: 10vh;
    /* margin-bottom: 15vh;
    margin-left: 17vh; */
  }