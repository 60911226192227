#game-over {
    font-family: 'Pokemon', sans-serif; 
    position: absolute;
    top: 20vh;
    height: 80vh;
    width: 100%;
    background-image: url('../img/ww-backer.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
} 

.win-coins {
  position: absolute;
  right: 0;
  height: 100vh;
  color: white; /* White text color */
  z-index: 99999; /* Ensure the log view is on top of other elements */
}

.game-over-container {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  .game-over-logo {
    width: 50vw; /* adjust as needed */
    height: auto;
    animation: pulse 1.5s ease-in-out infinite 0s alternate;
  }
  
  .game-over-button-container {

    display: flex;
    justify-content: space-between;
    width: 60%;
  }
  
  .game-over-button {
    padding: 2vh;
    width: 45vh; /* Decreased to allow space for margin */
    height: 13vh;
    font-family: 'Pokemon', sans-serif;
    border-radius: 50px;
    font-weight: bold;
    border: 2px solid black;
    background-color: white;
    color: black;
    font-size: 3.5vh;
    cursor: pointer;
    margin: 2vh; /* Added margin to ensure space between buttons */
}


  .game-over-button:hover {
    background-color: #f8f8f8;
  }