.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2em;
    height: 20vh;
    width: 100vw;
    position: absolute;
    background-image: url('../img/navbar/backer.jpg');
    background-size: cover;
    box-sizing: border-box;
  }
  
  .nav-section {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nav-section.burger,
  .nav-section.diamond,
  .nav-section.coins,
  .nav-section.team-rank {
    flex-basis: 18%;
  }

  .nav-section.diamond .item.diamond {
    height: 80%;
    width: auto;
    position: absolute;
  }

  .nav-section.burger .item.burger {
    height:80%;
    width: auto;
    position: absolute;
  }

  .nav-section.coins .team-rank-icon {
    height: 80%;
    width: auto;
    position: absolute;
  }

  .nav-section.team-rank .team-rank-icon {
    height: 80%;
    width: auto;
    position: absolute;
  }
  
  .nav-section.team-rank {
    flex-basis: 15%;
    justify-content: flex-end; /* Align items to the right */
  }

  .nav-section.burger {
    flex-basis: 15%;
    justify-content: flex-start; /* Align items to the left */
  }
  
  .nav-section.logo-section {
    flex-basis: 34%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .nav-section.logo-section img.logo {
    max-height: 100%;
    width: auto;
    object-fit: contain;
  }
  
  
  .nav .item {
    max-width: 100%; /* Ensure the image does not exceed container width */
    max-height: 100%; /* Ensure the image does not exceed container height */
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .nav .item:active {
    transform: scale(0.9);
  }
  
  .nav .coins {
    position: relative;
  }
  
  .nav .coins img.item {
    position: relative;
    z-index: 1;
  }
  
  .nav .coinHolderImage {
    transition: transform 0.3s;
  }
  
  .nav-section.coins:active .coinHolderImage {
    transform: scale(0.9);
  }
  
  .nav .coinTextContainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2; /* Makes the coinTextContainer stack on top of the coin holder image */
  }
  
  .nav .coinCount {
    color: white;
    font-family: 'LilitaOne-Regular', sans-serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-weight: bold;
    font-size: 7vh;
  }
  .dropdown {
    position: absolute;
    top: calc(100% - 5vh);
    left: 2vh;
    width: 25vw;
    height: 65vh;
    display: flex;
    flex-direction: column;
    align-items: center; /* Horizontally center items */
    justify-content: center; /* Vertically center items */
  }
  
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 80%;
    width: 90%;
  }
  
  .panel-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    align-items: center;
    justify-content: center;
  }
  
  .panel {
    position: absolute;

    left: 0;
    display: flex;
    width: 100%;
    justify-content: center;
    height: 100%;
    z-index: 1;
  }
  
  .button-container {
    position: relative;
    z-index: 2;
    display: flex;
    top: 3vh;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 80%; /* 80% height of the panel-container */
    width: 90%; /* Adjust as needed */
  }
  
  .dropdown-item {
    height: 25%; /* Each button will take up 20% of the button-container's height */
    width: 100%; /* Full width */
    cursor: pointer;
    transition: transform 0.3s;
  }
  
  .dropdown-item:active {
    transform: scale(0.9);
  }