#guess-wrapper {
    position: absolute;
    top: 20vh;
    height: 80vh;
    width: 100%;
    background-image: url('../img/ww-backer.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
}
  
.guess {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;  
    text-align: center;
}

.text-wrapper {
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black background */
    padding: 1rem; /* spacing around the text */
    border-radius: 10px; /* rounded corners */
    margin-bottom: 2rem; /* space between the text and the image */
    display: inline-block; /* adapt width to content */
}

.guess p {
    font-size: 3rem;
    color: #fff; /* white text */
    margin: 0; /* remove the default paragraph margins */
}
  
.guess img {
    max-height: 50%;
    height: auto;
    animation: pulse 1.5s ease-in-out infinite 0s alternate;
}
