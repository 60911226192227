.loading {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    backdrop-filter: blur(10px);
    z-index: 9999; /* Make sure it's above everything else */
  }
  
  .loading img {
    width: 100vh; /* Adjust the size of the logo */
    max-width: 80%; /* Ensure the logo doesn't exceed 80% of the viewport's width */
    margin-bottom: 20vh;
    height: auto;
    animation: pulse 1.5s ease-in-out infinite 0s alternate, shrink 1s ease-out 14s forwards;

  }

  @keyframes shrink {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  }
  
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.2);
    }
  }